<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <!-- 1. -->
        <section class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </section>
        <main v-if="is_API_loaded" class="space-y-5">
          <!-- <h1 v-if="!isNew" class="text-xl font-medium text-gray-text_dark">{{ data.title }}</h1> -->

          <!-- 新增後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h4 class="h4 mb-1">活動名稱 *</h4>
                  <ValidationProvider tag="div" class="relative" name="活動名稱" rules="required|max:50" v-slot="{ errors }">
                    <vs-input v-model="data.title"></vs-input>

                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 class="h4 mb-1">開始時間 *</h4>
                    <vs-input type="datetime-local" v-model="_vdate" :min="_today" />
                  </div>

                  <div>
                    <h4 class="h4 mb-1">結束時間 *</h4>
                    <vs-input :disabled="!data.vdate" type="datetime-local" v-model="_edate" :min="data.vdate" />
                  </div>
                </div>

                <div>
                  <h4 class="h4 mb-1">活動對象 *</h4>
                  <vs-select v-if="vip.length" v-model="data.smvno">
                    <vs-option value="不限對象" label="不限對象">不限對象</vs-option>
                    <vs-option value="全體會員" label="全體會員">全體會員</vs-option>
                    <vs-option v-for="vip in vip" :key="vip.smvno" :value="vip.smvno" :label="vip.title">{{ vip.title }}</vs-option>
                  </vs-select>
                </div>
              </article>
              <article class="box border-l-2 border-success" style="border-bottom-left-radius: 0px; border-top-left-radius: 0px">
                <div v-if="isNew">
                  <div class="flex items-center justify-between mb-1">
                    <h4 class="h4 text-success">優惠碼 *</h4>
                    <p class="text-gray-400">※ 新增後無法修改</p>
                  </div>
                  <div>
                    <ValidationProvider tag="div" class="relative" name="優惠碼" rules="required|max:50" v-slot="{ errors }">
                      <vs-input v-model.trim="data.coupon" placeholder="輸入自定義的優惠碼，新增後無法修改。"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </div>

                <section v-if="!isNew">
                  <h4 class="h4 text-success mb-1">優惠碼</h4>
                  <div class="flex items-center space-x-2">
                    <pre class="bg-gray-200 text-gray-text_dark w-fit text-xl">{{ data.coupon }}</pre>
                    <vs-button @click="copyToClipboard" color="success" size="mini" transparent>
                      <svg-icon icon-class="copy" className="success xs"></svg-icon>

                      <span key="點擊複製" v-if="!copyStatus" class="font-medium text-xs ml-1">點擊複製</span>
                      <span key="複製成功!" v-else class="font-medium text-xs ml-1">複製成功!</span>
                    </vs-button>
                  </div>
                </section>

                <div v-if="!isNew">
                  <h4 class="h4">已被使用次數</h4>
                  <p v-if="data.usedtimes === 0" class="text-gray-400">尚未被使用過</p>
                  <p v-else>{{ data.usedtimes }} 次</p>
                </div>
              </article>

              <article class="box">
                <div>
                  <div class="flex items-center justify-between mb-1">
                    <h4 class="h4">單筆消費門檻 *</h4>
                    <p class="text-gray-400">※ 無消費門檻請填入0</p>
                  </div>

                  <ValidationProvider
                    tag="div"
                    class="relative"
                    name="單筆消費門檻"
                    rules="required|integer|min_value:0"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                      <div>
                        <span>NT$</span>
                      </div>
                      <input type="number" class="w-full h-full" v-model.number="data.pays" />
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div>
                  <h4 class="h4 mb-1">折扣類型 *</h4>
                  <div class="flex items-start">
                    <vs-radio @input="handleRadio" success v-model="data.dtype" :val="0">百分比</vs-radio>
                    <vs-radio @input="handleRadio" success v-model="data.dtype" :val="1">固定金額</vs-radio>
                    <vs-radio @input="handleRadio" success v-model="data.dtype" :val="2">免運費</vs-radio>
                  </div>
                </div>

                <div v-if="data.dtype === 0 || data.dtype === 1">
                  <h4 class="h4 mb-1">折扣金額 *</h4>
                  <ValidationProvider
                    v-if="data.dtype === 0"
                    tag="div"
                    class="relative"
                    name="折扣金額"
                    rules="required|integer|min_value:1|max_value:99"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl px-3 overflow-hidden h-10">
                      <input type="number" class="w-full h-full" v-model.number="data.discount" />
                      <div>
                        <p class="w-14">% OFF</p>
                      </div>
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="data.dtype === 1"
                    tag="div"
                    class="relative"
                    name="折扣金額"
                    rules="required|integer|min_value:1"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                      <div>
                        <span>NT$</span>
                      </div>
                      <input type="number" class="w-full h-full" v-model.number="data.discount" />
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </article>
              <article class="box">
                <div>
                  <div class="mb-1 flex items-center justify-between">
                    <h4 class="h4">使用次數上限 *</h4>
                    <p class="text-gray-400">※ 無使用次數上限請填入0</p>
                  </div>
                  <ValidationProvider
                    tag="div"
                    class="relative"
                    name="使用次數上限"
                    rules="required|integer|min_value:0|max:10"
                    v-slot="{ errors }"
                  >
                    <vs-input type="number" v-model.number="data.usetimes"></vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div>
                  <h4 class="h4 mb-1">每人使用次數限制 *</h4>
                  <div class="flex items-center space-x-2">
                    <vs-radio :disabled="data.smvno === '不限對象'" success v-model="data.usetype" :val="0">每人一次</vs-radio>
                    <vs-radio success v-model="data.usetype" :val="1">無限次數</vs-radio>
                  </div>
                </div>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h3 class="h3">摘要</h3>
                <div class="space-y-2">
                  <p v-if="_summary_list_1" class="list_item">{{ _summary_list_1 }}</p>
                  <p v-if="_summary_list_2" class="list_item">{{ _summary_list_2 }}</p>
                </div>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="delete_dialog = true" v-if="!isNew" size="large" color="danger" border>
              <span class="font-medium">刪除</span>
            </vs-button>
            <router-link v-else :to="{ name: 'coupons' }">
              <vs-button size="large" border>
                <span class="font-medium">取消</span>
              </vs-button>
            </router-link>

            <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="save(invalid)">
              <span v-if="isNew" class="font-medium">新增</span>
              <span v-else class="font-medium">儲存</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNew" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNew" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此行銷活動？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的行銷活動無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteFunc" size="large" transparent color="danger" ref="deleteFuncBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import dateTimeValidation from '@/assets/mixins/dateTimeValidation.js'
export default {
  name: 'coupon',
  mixins: [dateTimeValidation],
  data() {
    return {
      is_API_loaded: false,
      isNew: false,
      alertTitle: '',
      alertAfterCreated: false,
      delete_dialog: false,
      data: {
        title: '',
        usetimes: 0,
        usetype: 1,
        coupon: '',
        dtype: 0,
        discount: '',
        pays: 0,
        smvno: '不限對象',
        vdate: '',
        edate: '',
      },
      vip: [], // 會員分級列表
      copyStatus: false,
    }
  },
  computed: {
    _discount() {
      if (this.data.discount) {
        return (100 - this.data.discount) / 10
      } else {
        return '_'
      }
    },
    // 今天日期時間
    _today() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      const hour = today.getHours()
      const minute = today.getMinutes()

      function plusZero(value) {
        if (value < 10) return '0' + value
        else return value
      }

      return `${year}-${plusZero(month)}-${plusZero(day)}T${hour}:${minute}`
    },
    // 抓出會員分級title
    _membership() {
      let obj = this.vip.find((item) => item.smvno === this.data.smvno)

      if (obj) return obj.title
    },
    _summary_list_1() {
      if (this.data.pays <= 0 && this.data.dtype === 0 && this.data.discount > 0) return `不限金額皆可使用此 ${this._discount}折 優惠碼。`
      if (this.data.pays <= 0 && this.data.dtype === 1 && this.data.discount > 0)
        return `不限金額皆可使用此 NT$${this.$options.filters.currency(this.data.discount)} 優惠碼。`
      if (this.data.pays <= 0 && this.data.dtype === 2) return `不限金額皆可使用此 免運費 優惠碼。`

      if (this.data.pays > 0 && this.data.dtype === 0 && this.data.discount > 0)
        return `單筆消費(不含運費)滿 NT$${this.$options.filters.currency(this.data.pays)}元，即可使用此 ${this._discount}折 優惠碼。`
      if (this.data.pays > 0 && this.data.dtype === 1 && this.data.discount > 0)
        return `單筆消費(不含運費)滿 NT$${this.$options.filters.currency(this.data.pays)}元，即可使用此 NT$${this.$options.filters.currency(
          this.data.discount
        )} 優惠碼。`
      if (this.data.pays > 0 && this.data.dtype === 2)
        return `單筆消費(不含運費)滿 NT$${this.$options.filters.currency(this.data.pays)}元，即可使用此 免運費 優惠碼。`
    },
    _summary_list_2() {
      if (this.data.smvno === '不限對象') return '此優惠碼 所有人 皆可使用。'
      if (this.data.smvno === '全體會員') return '此優惠碼 全體會員 皆可使用。'
      else return `此優惠碼只有 ${this._membership} 可以使用 。`
    },
    _vdate: {
      get() {
        let vdate = this.data.vdate.replace(' ', 'T').substr(0, 16)
        return vdate
      },
      set(val) {
        this.data.vdate = val
      },
    },
    _edate: {
      get() {
        let edate = this.data.edate.replace(' ', 'T').substr(0, 16)
        return edate
      },
      set(val) {
        this.data.edate = val
      },
    },
  },
  watch: {
    $route(to, from) {
      this.isNewFunc()
    },
    'data.smvno'(val) {
      if (val === '不限對象') {
        this.data.usetype = 1
      }
    },
  },
  async created() {
    let status1 = await this.isNewFunc()
    let status2 = await this.getVIP()
    if (status1 && status2) this.is_API_loaded = true
  },
  methods: {
    isNewFunc() {
      // 判斷是新增 還是 編輯
      if (this.$route.name === 'new-coupon') {
        this.isNew = true
        return true
      } else {
        this.isNew = false
        return this.getData()
      }
    },
    // 3.5.4.2 優惠碼資料-讀取
    getData() {
      return this.$axios({
        url: 'front/store/market/getCouponUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smcno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          console.log('優惠碼資料-讀取', res.data.Data)
          let data = res.data.Data
          if (data.smvno === '' && data.smtype === 0) data.smvno = '不限對象'
          if (data.smvno === '' && data.smtype === 1) data.smvno = '全體會員'
          this.data = data
          return true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.5.4.3 優惠碼資料-新增
    // 3.5.4.4 優惠碼資料-修改
    save(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      if (this.dateTimeValidationMixin(this._vdate, this._edate) === false) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = ''

      let data = {
        lang: 0,
        title: this.data.title,
        smtype: this.data.smvno === '不限對象' ? 0 : 1,
        smvno: this.data.smvno === '不限對象' || this.data.smvno === '全體會員' ? '' : this.data.smvno,
        usetype: this.data.usetype,
        usetimes: this.data.usetimes,
        coupon: this.data.coupon,
        pays: this.data.pays,
        dtype: this.data.dtype,
        // 免運費的話，discount傳0
        discount: this.data.dtype === 2 ? 0 : this.data.discount,
        vdate: this._vdate + ':00',
        edate: this._edate + ':00',
      }

      if (this.isNew) {
        url = 'front/store/market/setCouponData.php'
      } else {
        url = 'front/store/market/uCouponData.php'
        data.smcno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNew) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.data.title
            this.$router.replace({
              // 跳轉到新頁面
              name: 'edit-coupon',
              params: {
                id: res.data.Data.smcno,
              },
            })
          } else {
            this.getData()
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.5.4.5 優惠碼資料-刪除
    deleteFunc() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteFuncBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/market/dCouponData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smcno: this.$route.params.id,
        },
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.Success) {
          this.delete_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'coupons',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.2.2.1 讀取會員等級列表
    getVIP() {
      return this.$axios({
        url: 'front/store/mvip/getMVipList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.vip = res.data.Data
          return true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 複製優惠碼到剪貼簿
    async copyToClipboard() {
      if (this.copyStatus) return
      try {
        await navigator.clipboard.writeText(this.data.coupon)
        this.copyStatus = true

        setTimeout(() => {
          this.copyStatus = false
        }, 2000)
      } catch (err) {
        alert('您的瀏覽器版本不支援此種複製方式，請手動複製。', err)
      }
    },
    handleRadio() {
      this.data.discount = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}

.list_item {
  @apply ml-0 relative px-4;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: var(--gray-border);
  }
}

::v-deep .vs-alert__title {
  height: 100%;
}

::v-deep .vs-alert__content {
  min-height: 0px;
}
</style>
